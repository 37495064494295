@use "../../Stylesheets/partials/screenMixin";

.hero-description-container {
   width: fit-content;
   p {
      margin: 0;
      font-size: 2.2rem;
      text-align: center;
      font-family: roboto;
      font-weight: 300;

      span {
         font-weight: 200;
         &.code {
            color: rgb(255, 0, 0);
            font-weight: 300;
         }
         &.website {
            color: rgb(4, 0, 255);
            font-weight: 300;
         }
      }
   }
   & div {
      margin: auto;
      margin-top: 1em;
      width: fit-content;
      font-size: 1.6rem;
      & a {
         text-decoration: none;
         padding: 0.5em;
         background-color: transparent;
         border: 3px solid rgba(0, 128, 0, 0.514);
         border-radius: 10px;
         font-family: roboto;
         color: rgb(78, 78, 78);
         cursor: pointer;
      }
   }
   @include screenMixin.respond(desktop) {
      & p {
         text-align: end;
         font-size: 2.8rem;
      }
      & div {
         margin: 1em 0 auto auto;
      }
   }
}
