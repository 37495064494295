@use "../../Stylesheets/partials/screenMixin";

.project-display-container {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   margin: auto;

   @include screenMixin.respond(desktop) {
      flex-direction: row;
      width: 80%;
      flex-wrap: wrap;
   }
}
