@use "../../Stylesheets/partials/screenMixin";

.avatar-container {
   width: fit-content;
   margin: auto;
   position: relative;

   &__img {
      object-fit: cover;
      width: 20rem;
      height: 20rem;
      object-fit: cover;
      object-position: 100% 0;
   }
   &__blur-div {
      width: 100%;
      height: 50px;
      position: absolute;
      bottom: -25px;
      background: rgb(255, 255, 255);
      filter: blur(10px);
   }

   @include screenMixin.respond(desktop) {
      &__img {
         width: 30rem;
         height: 55rem;
      }
   }
}
