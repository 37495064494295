:export {
   desktop: 1200;
}
@mixin respond($breakpoint) {
   @if $breakpoint==desktop {
      @media only screen and (min-width: 1200px) {
         @content;
      }
   }
   @if $breakpoint==tablet {
      @media only screen and (max-width: 1200px) {
         @content;
      }
   }
   @if $breakpoint==mobile {
      @media only screen and (max-width: 480px) {
         @content;
      }
   }
}
