@use "../../Stylesheets/partials/screenMixin";

.hero-section {
   width: 95%;
   display: flex;
   margin: auto;

   gap: 2.5em;
   flex-direction: column;
   align-items: center;
   @include screenMixin.respond(desktop) {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      gap: 0;

      width: 80%;
   }
}
