@font-face {
   font-family: roboto;
   src: url(./Roboto-Black.ttf);
   font-weight: bold;
}
@font-face {
   font-family: roboto;
   src: url(./Roboto-Bold.ttf);
   font-weight: bold;
}
@font-face {
   font-family: roboto;
   src: url(./Roboto-BlackItalic.ttf);
   font-style: italic;
}
@font-face {
   font-family: roboto;
   src: url(./Roboto-Light.ttf);
   font-weight: 200;
}
@font-face {
   font-family: roboto;
   src: url(./Roboto-BoldItalic.ttf);
   font-weight: bold;
   font-style: italic;
}
@font-face {
   font-family: roboto;
   src: url(./Roboto-Thin.ttf);
   font-weight: 100;
}
@font-face {
   font-family: roboto;
   src: url(./Roboto-Medium.ttf);
   font-weight: 300;
}

@font-face {
   font-family: roboto;
   src: url(./Roboto-MediumItalic.ttf);
   font-weight: 300;
   font-style: italic;
}

@font-face {
   font-family: roboto;
   src: url(./Roboto-Black.ttf);
}
